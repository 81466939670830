import axios from 'axios';

const fix = axios.create({
    baseURL: process.env.VUE_APP_BASE_FIXTURE_URL,
});

fix.defaults.headers.post['Content-Type'] = 'application/json';
fix.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

fix.defaults.method = "get";

export default fix;